import * as React from 'react';
import { useStaticQuery, graphql, HeadFC } from 'gatsby';

import '../styles/main.scss';
import SEO from '../components/containers/SEO';
import MainTemplate from '../components/templates/MainTemplate';
import HomePageHero from '../components/organisms/HomePageHero';
import HomePageInfoCards from '../components/organisms/HomePageInfoCards';
import HomePageClientSection from '../components/organisms/HomePageClientSection';
import HomePageSolutionSection from '../components/organisms/HomePageSolutionSection';
import HomePageBlogSection from '../components/organisms/HomePageBlogSection';
import HomePageFooter from '../components/organisms/HomePageFooter';

interface QueryResult {
  allContentfulPost: {
    nodes: BlogPost[];
  };
}

const IndexPage: React.FunctionComponent = () => {
  const data: QueryResult = useStaticQuery(graphql`
    query LoadHomeArticles {
      allContentfulPost(sort: { order: DESC, fields: [createdAt] }, limit: 2) {
        nodes {
          slug
          title
          excerpt
          createdAt

          heroImage {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 1280
            )
          }

          body {
            childMarkdownRemark {
              timeToRead
            }
          }

          metadata {
            tags {
              name
            }
          }
          category {
            name
          }
        }
      }
    }
  `);

  return (
    <MainTemplate
      heroContent={<HomePageHero />}
      footerContent={<HomePageFooter />}
    >
      {/* Cards info section */}
      <HomePageInfoCards id="info" />

      {/* Solutions sections */}
      <HomePageSolutionSection id="solutions" />

      {/* Clients section */}
      <HomePageClientSection id="clients" />

      {/* Blog section */}
      <HomePageBlogSection id="blog" posts={data.allContentfulPost.nodes} />
    </MainTemplate>
  );
};

export default IndexPage;

export const Head: HeadFC<QueryResult> = () => (
  <SEO
    title="Experiência do Paciente | Soluções para Hospitais"
    description="Melhore a experiência do paciente da sua instituição de saúde com as soluções da Quality24. Aplicativo que avalia a satisfação dos pacientes 24/7. Confira!"
    // image={post.heroImage.file.url}
  />
);
