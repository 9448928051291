import { Heading } from '@quality24/design-system';
import classNames from 'classnames';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import testimonials from '../../../data/testimonials';

import useOnScreen from '../../../utils/useOnScreen';
import ContactForm from '../ContactForm';
import { Testimonial } from '../Testimonial';

const HomePageFooter: React.FunctionComponent = () => {
  const elementRef = React.useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(elementRef);

  // use media query for tablet as smaller than 1200px, 1000px for mobile
  const isMobile = useMediaQuery({ maxWidth: 1000 });

  return (
    <div
      ref={elementRef}
      className={classNames('container px-0 px-lg-4 anim anim-slide-up', {
        visible: isVisible,
      })}
    >
      <div
        style={{
          display: isMobile ? 'block' : 'grid',
          gridTemplateColumns: isMobile ? '' : '1fr 1fr',
          marginTop: '3rem',
        }}
      >
        <div className="px-3 pl-lg-0" style={{ height: '618px' }}>
          <Heading className="mb-3" as="h2" size="lg2" weight="bold">
            O que falam sobre nós
          </Heading>
          <div className="mt-3">
            <Testimonial testimonials={testimonials} />
          </div>
        </div>

        <div
          id="contact-us"
          className="w-100 px-3 px-md-0 mt-3 mt-lg-0"
          style={{
            height: '768px',
          }}
        >
          <Heading className="mb-3" as="h2" size="lg2" weight="bold">
            Contato
          </Heading>
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default HomePageFooter;
