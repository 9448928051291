import bpLogo from '../../../assets/logos/logo-bp.webp';
import rhpLogo from '../../../assets/logos/logo-rhp.webp';
import rmdsLogo from '../../../assets/logos/logo-rmds.webp';
import hcorLogo from '../../../assets/logos/logo-hcor.webp';
import ocLogo from '../../../assets/logos/logo-oc.webp';
import acreditaLogo from '../../../assets/logos/logo-acredita-saude.webp';
import cassemsLogo from '../../../assets/logos/logo-cassems.webp';

export interface ClientData {
  id: string;
  logo: string;
  name: string;
}

// eslint-disable-next-line import/prefer-default-export
export const clients: Array<ClientData> = [
  { id: 'bp', logo: bpLogo, name: 'Logo Beneficência Portuguesa' },
  { id: 'rhp', logo: rhpLogo, name: 'Logo Real Hospital Português' },
  { id: 'rmds', logo: rmdsLogo, name: 'Logo Mater Dei' },
  { id: 'hcor', logo: hcorLogo, name: 'Logo HCor' },
  { id: 'oc', logo: ocLogo, name: 'Logo Oncoclínicas' },
  { id: 'acredita', logo: acreditaLogo, name: 'Logo Acredita Saúde' },
  { id: 'cassemsLogo', logo: cassemsLogo, name: 'Logo CASSEMS' },
];
