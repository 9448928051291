import {
  faClipboardUser,
  faHeadSideMedical,
  faUserNurseHair,
} from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import classNames from 'classnames';

import InfoCard from '../../molecules/InfoCard';
import useOnScreen from '../../../utils/useOnScreen';

import SquareGraphic from '../../../assets/graphic-1.inline.svg';
import * as styles from './HomePageInfoCards.module.scss';

export type Props = React.HTMLAttributes<HTMLElement>;

const HomePageInfoCards: React.FunctionComponent<Props> = ({
  className,
  ...props
}) => {
  const elementRef = React.useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(elementRef);

  return (
    <>
      <div
        ref={elementRef}
        className={classNames(
          styles.wrapper,
          'container row g-3 g-lg-5',
          className,
        )}
        {...props}
      >
        <div className="col-12 col-lg-4">
          <InfoCard
            className={classNames('anim anim-bounce-in', {
              visible: isVisible,
            })}
            icon={faClipboardUser}
            cardTitle="Gestão"
          >
            Controle em tempo real, organização de fluxos hospitalares, análise
            de produtividade dos profissionais, diminuição de detratores e
            melhor satisfação dos pacientes
          </InfoCard>
        </div>
        <div className="col-12 col-lg-4">
          <InfoCard
            className={classNames('anim anim-bounce-in', {
              visible: isVisible,
            })}
            icon={faUserNurseHair}
            cardTitle="Operação"
          >
            Otimize o trabalho das equipes de enfermagem e direcione os chamados
            para os departamentos apropriados. Melhore o fluxo hospitalar e não
            sobrecarregue a enfermagem
          </InfoCard>
        </div>
        <div className="col-12 col-lg-4">
          <InfoCard
            className={classNames('anim anim-bounce-in', {
              visible: isVisible,
            })}
            icon={faHeadSideMedical}
            cardTitle="Pacientes"
          >
            Melhore o atendimento, o bem-estar dos pacientes e o número de
            detratores. Os pacientes internados se conectam aos hospitais,
            tornando o atendimento mais exclusivo
          </InfoCard>
        </div>
      </div>
      <SquareGraphic className={styles.graphic} />
    </>
  );
};

export default HomePageInfoCards;
