import { Button, Heading, Icon, Image, Text } from '@quality24/design-system';
import {
  faArrowDown,
  faArrowUp,
  faClock,
  faRight,
  faPrescriptionBottleMedical,
  faUserDoctorHairLong,
  faFaceSmile,
} from '@fortawesome/pro-solid-svg-icons';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Link } from 'gatsby';
import React from 'react';
import classNames from 'classnames';

import Video from '../../atoms/Video';
import HighlightCircle from '../../molecules/HighlightCircle';
import useOnScreen from '../../../utils/useOnScreen';

import frame from '../../../assets/macbook-frame.webp';
import poster from '../../../assets/homepage-poster.webp';
import video from '../../../assets/videos/homepage.mp4';
import videoWebm from '../../../assets/videos/homepage.webm';

import SquareGraphic from '../../../assets/graphic-1.inline.svg';
import CircleGraphic from '../../../assets/graphic-2.inline.svg';
import BarsGraphic from '../../../assets/graphic-3.inline.svg';
import * as styles from './HomePageHero.module.scss';

const HeroSection: React.FunctionComponent = () => {
  const elementRef = React.useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(elementRef);

  // Breakpoints
  const breakpoints = useBreakpoint();
  const isMobile = React.useMemo(() => breakpoints.lg === false, [breakpoints]);

  return (
    <div
      ref={elementRef}
      className={classNames(
        styles.wrapper,
        'container py-4 d-flex flex-column anim anim-slide-up',
        { visible: isVisible },
      )}
    >
      <Heading as="h1" className="mb-4" size="xl" weight="bold">
        Hospitais complexos exigem controles simplificados
      </Heading>
      <Heading as="h2" className="mb-4 mb-md-5" size="lg1">
        Transformamos a comunicação hospitalar e a experiência do paciente
      </Heading>

      <div className={styles.laptop}>
        <Image className={styles.frame} src={frame} alt="laptop-frame" />
        <div className={styles.screen}>
          <Video
            className={styles.video}
            width={678}
            autoPlay={!isMobile}
            poster={poster}
            controls
            muted
            loop
          >
            <source src={videoWebm} type="video/webm" />
            <source src={video} type="video/mp4" />
            Dashboard principal
          </Video>
        </div>
      </div>

      <Text size="lg2" className="mb-4 mb-md-5">
        Obtenha dados em tempo real da operação hospitalar e eleve a
        produtividade das equipes
      </Text>

      <Link to="/#solutions">
        <Button variant="outline" size="lg" appendIcon={faRight}>
          Conheça nossos cases de sucesso
        </Button>
      </Link>

      <div className={classNames(styles.highlightSection, 'row g-0 g-md-5')}>
        <div className="col-12 offset-md-2 col-md-4 offset-lg-0 col-lg-3 d-flex flex-justify-center">
          <HighlightCircle
            icon={faUserDoctorHairLong}
            prependSign="+"
            number={25000}
          >
            horas de enfermagem poupadas
          </HighlightCircle>
        </div>
        <div className="col-12 col-md-4 col-lg-3 d-flex flex-justify-center">
          <HighlightCircle
            icon={faFaceSmile}
            prependSign={<Icon icon={faArrowDown} size="lg" />}
            appendSign="%"
            number={40}
          >
            de redução no número de detratores
          </HighlightCircle>
        </div>
        <div className="col-12 offset-0 offset-md-2 offset-lg-0 col-md-4 col-lg-3 d-flex flex-justify-center">
          <HighlightCircle
            icon={faPrescriptionBottleMedical}
            prependSign="-"
            appendSign="%"
            number={90}
          >
            de evasão de itens hospitalares
          </HighlightCircle>
        </div>
        <div className="col-12 col-md-4 col-lg-3 d-flex flex-justify-center">
          <HighlightCircle
            icon={faClock}
            prependSign={<Icon icon={faArrowUp} size="lg" />}
            appendSign="%"
            number={70}
          >
            de redução no tempo de atendimento ao paciente e solicitações
            internas
          </HighlightCircle>
        </div>
      </div>

      <SquareGraphic className={classNames(styles.graphic, styles.square)} />
      <CircleGraphic className={classNames(styles.graphic, styles.circle)} />
      <BarsGraphic className={classNames(styles.graphic, styles.bars)} />
    </div>
  );
};

export default HeroSection;
