import { Button, Heading } from '@quality24/design-system';
import { faRight } from '@fortawesome/pro-solid-svg-icons';
import { navigate } from 'gatsby';
import React from 'react';
import classNames from 'classnames';

import useOnScreen from '../../../utils/useOnScreen';

import * as styles from './HomePageBlogSection.module.scss';
import PostCard from '../../molecules/PostCard';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  posts: BlogPost[];
}

const HomePageBlogSection: React.FunctionComponent<Props> = ({
  className,
  posts,
  ...props
}) => {
  const elementRef = React.useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(elementRef);

  return (
    <div
      ref={elementRef}
      className={classNames(
        styles.wrapper,
        'container py-4 d-flex flex-column anim anim-slide-up',
        { visible: isVisible },
        className,
      )}
      {...props}
    >
      <Heading
        as="h2"
        className="mb-5"
        size="xl"
        weight="bold"
        color="secondary"
        style={{ textAlign: 'center' }}
      >
        Novidades do blog
      </Heading>

      <div className="row g-4 mb-2">
        {posts?.map((n) => (
          <div key={n.slug} className="col-12 col-lg-6">
            <PostCard
              title={n.title}
              excerpt={n.excerpt}
              image={n.heroImage.gatsbyImageData}
              tags={[n.category]}
              onClick={() => navigate(`/blog/${n.slug}`)}
            />
          </div>
        ))}
      </div>

      <Button
        variant="link"
        size="lg"
        appendIcon={faRight}
        onClick={() => navigate('/blog')}
      >
        Ver mais
      </Button>
    </div>
  );
};

export default HomePageBlogSection;
