import React from 'react';
import classNames from 'classnames';
import { Button, ButtonProps } from '@quality24/design-system';

import * as styles from './GradientButton.module.scss';

export interface Props extends Omit<ButtonProps, 'size'> {
  active?: boolean;
  children: React.ReactNode;
}

const GradientButton: React.FunctionComponent<Props> = ({
  className,
  active,
  children,
  ...props
}) => {
  return (
    <Button
      className={classNames(styles.button, className, { active })}
      size="lg"
      {...props}
    >
      {children}
    </Button>
  );
};

export default GradientButton;
