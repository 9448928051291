import { Heading, Image } from '@quality24/design-system';
import {
  faHeartCircleCheck,
  faUserNurseHair,
  faClipboardUser,
  faStretcher,
} from '@fortawesome/pro-solid-svg-icons';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React from 'react';
import classNames from 'classnames';

import Carousel from '../Carousel';
import HighlightCircle from '../../molecules/HighlightCircle';
import { clients } from './data';

import SquareGraphic from '../../../assets/graphic-1.inline.svg';
import * as styles from './HomePageClientSection.module.scss';

export type Props = React.HTMLAttributes<HTMLElement>;

const HomePageClientSection: React.FunctionComponent<Props> = ({
  className,
  ...props
}) => {
  const breakpoints = useBreakpoint();

  return (
    <>
      <div
        className={classNames(
          styles.wrapper,
          'container py-4 d-flex flex-column anim-slide-up',
          className,
        )}
        {...props}
      >
        <Heading
          as="h2"
          className="mb-5"
          size="xl"
          weight="bold"
          color="secondary"
          style={{ textAlign: 'center' }}
        >
          Nossos parceiros
        </Heading>

        <Carousel
          className={styles.carousel}
          deviceType={breakpoints.xs ? 'mobile' : 'desktop'}
          arrows={false}
          infinite
          autoPlay
        >
          {clients.map((c) => (
            <div key={c.id} className="d-flex flex-justify-center">
              <Image
                className={styles.image}
                src={c.logo}
                alt={c.name}
                style={{ width: '100%' }}
              />
            </div>
          ))}
        </Carousel>

        <div className="row  g-0 g-md-5">
          <div className="col-12 offset-md-2 col-md-4 offset-lg-0 col-lg-3 d-flex flex-justify-center">
            <HighlightCircle
              icon={faStretcher}
              prependSign="+"
              number={3000}
              onLightBackground
            >
              leitos em hospitais
            </HighlightCircle>
          </div>
          <div className="col-12 col-md-4 col-lg-3 d-flex flex-justify-center">
            <HighlightCircle
              icon={faClipboardUser}
              prependSign="+"
              number={140000}
              onLightBackground
            >
              pacientes atendidos por ano
            </HighlightCircle>
          </div>
          <div className="col-12 offset-md-2 col-md-4 offset-lg-0 col-lg-3 d-flex flex-justify-center">
            <HighlightCircle
              icon={faUserNurseHair}
              prependSign="+"
              number={350000}
              onLightBackground
            >
              demandas atendidas
            </HighlightCircle>
          </div>
          <div className="col-12 col-md-4 col-lg-3 d-flex flex-justify-center">
            <HighlightCircle
              icon={faHeartCircleCheck}
              prependSign="+"
              appendSign="%"
              number={90}
              onLightBackground
            >
              de engajamento dos pacientes
            </HighlightCircle>
          </div>
        </div>
      </div>

      <SquareGraphic className={styles.graphic} />
    </>
  );
};

export default HomePageClientSection;
