// extracted by mini-css-extract-plugin
export var active = "Testimonial-module--active--16d38";
export var card = "Testimonial-module--card--fdd8b";
export var cardContainer = "Testimonial-module--card-container--22f8a";
export var desktop = "Testimonial-module--desktop--7a2b1";
export var graphic = "Testimonial-module--graphic--e9b90";
export var line = "Testimonial-module--line--a748e";
export var mobile = "Testimonial-module--mobile--96888";
export var next = "Testimonial-module--next--8e361";
export var prev = "Testimonial-module--prev--7fc34";
export var quotationMark = "Testimonial-module--quotation-mark--03cc2";