import { faQuoteLeft } from '@fortawesome/pro-solid-svg-icons';
import { Icon, Text } from '@quality24/design-system';
import classNames from 'classnames';
import * as React from 'react';

import Card from '../../atoms/Card';

import BarsGraphic from '../../../assets/graphic-3.inline.svg';
import * as styles from './Testimonial.module.scss';

export interface CardsInfo {
  className?: string;
  order: number;
  photo?: string;
  author: string;
  role: string;
  text: string;
  desktopMode: boolean;
  onChange?: (order: number) => void;
}

export const TestimonialCard: React.FunctionComponent<CardsInfo> = ({
  order,
  photo,
  author,
  role,
  text,
  className,
  onChange,
  desktopMode,
}) => (
  <Card
    className={classNames(
      styles.card,
      className,
      desktopMode && styles.desktop,
      !desktopMode && styles.mobile,
    )}
    onClick={() => onChange?.(order)}
  >
    <div className={styles.cardContainer}>
      <BarsGraphic className={styles.graphic} />

      {/* Content */}
      <div className="flex-grow-1">
        <Icon
          className={styles.quotationMark}
          icon={faQuoteLeft}
          size={desktopMode ? 'lg' : 'md'}
          color="blue600_25"
        />
        <Text size="lg1">{text}</Text>
      </div>

      <div className={styles.line} />
      <div className="d-flex gap-2 mt-3 mb-2">
        {/* Photo */}
        <div
          style={{
            width: desktopMode ? '80px' : '68px',
            height: desktopMode ? '80px' : '68px',
            borderRadius: '50%',
            backgroundColor: '#f2f2f2',
            backgroundImage: `url('${photo}')`,
            backgroundSize: 'cover',
          }}
        />
        <div style={{ flex: 1 }}>
          <Text size="lg1" weight="bold">
            {author}
          </Text>
          <Text size="md">{role}</Text>
        </div>
      </div>
    </div>
  </Card>
);

export default TestimonialCard;
