import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import type { Testimonial as ITestimonial } from '../../../data/testimonials';
import TestimonialCard from './TestimonialCard';

import * as styles from './Testimonial.module.scss';
import Carousel from '../Carousel';

export interface Props {
  testimonials: Array<ITestimonial>;
}

export const Testimonial: React.FunctionComponent<Props> = ({
  testimonials,
}) => {
  const [selectedCard, setSelectedCard] = React.useState<number>(1);

  // use media query for tablet as smaller than 1200px, 1000px for mobile
  const isMobile = useMediaQuery({ maxWidth: 1000 });

  const getClass = React.useMemo(
    () => (n: number, currentSelectedCard: number) => {
      const prev =
        currentSelectedCard === 0
          ? testimonials.at(-1)?.order
          : currentSelectedCard - 1;
      const next =
        currentSelectedCard === testimonials.length - 1
          ? 0
          : currentSelectedCard + 1;

      if (n === currentSelectedCard) {
        return styles.active;
      }

      if (n === prev) {
        return styles.prev;
      }

      if (n === next) {
        return styles.next;
      }

      return '';
    },
    [testimonials],
  );

  return isMobile ? (
    <Carousel
      swipeable
      draggable
      responsive={{
        mobile: {
          breakpoint: { max: 1024, min: 0 },
          items: 1,
          partialVisibilityGutter: 20,
        },
      }}
      arrows={false}
      autoPlay={false}
      infinite={false}
      customTransition="all .5"
      transitionDuration={500}
      partialVisible
      ssr // means to render carousel on server-side.
    >
      {testimonials.map((testimonial) => (
        <TestimonialCard
          desktopMode={false}
          key={testimonial.order}
          {...testimonial}
        />
      ))}
    </Carousel>
  ) : (
    <div style={{ position: 'relative' }}>
      {testimonials.map((info) => (
        <TestimonialCard
          key={info.author}
          className={getClass(info.order, selectedCard)}
          onChange={(order) => setSelectedCard(order)}
          desktopMode
          {...info}
        />
      ))}
    </div>
  );
};

export default Testimonial;
