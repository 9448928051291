import monica from '../assets/testimonials/author-monica.webp';
import buzaid from '../assets/testimonials/author-buzaid.webp';
import maluf from '../assets/testimonials/author-maluf.webp';

export interface Testimonial {
  order: number;
  photo?: string;
  photoAlt?: string;
  author: string;
  role: string;
  text: string;
}

export const comments: Testimonial[] = [
  {
    order: 0,
    photo: buzaid,
    photoAlt: 'Dr. Antônio Carlos Buzaid',
    text: 'A Quality24 tem a expertise que poucas empresas de tecnologia possuem. Ela possibilita o verdadeiro patient-centered care e a gestão operacional baseada em dados, permitindo intervenção em tempo real e trazendo o que há de mais inovador em experiência do paciente e eficiência em saúde.',
    author: 'Dr. Antonio Carlos Buzaid',
    role: 'Oncologista, Diretor Médico Geral do Centro Oncológico da Beneficência Portuguesa de São Paulo',
  },
  {
    order: 1,
    photo: maluf,
    photoAlt: 'Dr. Fernando Maluf',
    text: 'As inovações tecnológicas estão transformando a rotina médica, beneficiando profissionais, pacientes e instituições. As soluções da Quality24 permitem que a operação hospitalar seja mais sustentável, mensurável e eficiente, enquanto o paciente se sente mais ouvido pela instituição.',
    author: 'Dr. Fernando Maluf',
    role: 'Oncologista e Fundador do Instituto Vencer o Câncer',
  },
  {
    order: 2,
    photo: monica,
    photoAlt: 'Logo do hospital BP',
    text: 'Aqui no BP Mirante prezamos por um atendimento assistencial personalizado pois sabemos que as necessidades individuais de cada cliente são diferentes. Ter ferramentas que permitam acolher às solicitações e aprimorar a experiência do cliente em tempo real são fundamentais. A solução da Quality24 nos permite atuar ao longo da jornada de internação dos clientes de uma forma bastante eficiente e assertiva.',
    author: 'Mônica Morgese Alves',
    role: 'Gerente-executiva da BP - A Beneficência Portuguesa de São Paulo',
  },
];

export default comments;
