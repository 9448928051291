import { Button, Heading, Image, Text } from '@quality24/design-system';
import { faRight } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'gatsby';
import React from 'react';
import classNames from 'classnames';
import { solutions } from './data';

import GradientButton from '../../atoms/GradientButton';
import GradientIcon from '../../atoms/GradientIcon';
import useOnScreen from '../../../utils/useOnScreen';

import BarsGraphic from '../../../assets/graphic-3.inline.svg';
import * as styles from './HomePageSolutionSection.module.scss';

export type Props = React.HTMLAttributes<HTMLElement>;

const HomePageSolutionSection: React.FunctionComponent<Props> = ({
  className,
  ...props
}) => {
  const [selected, setSelected] = React.useState(solutions[0].id);

  const elementRef = React.useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(elementRef);

  return (
    <div
      ref={elementRef}
      className={classNames(styles.wrapper, className)}
      {...props}
    >
      <div
        className={classNames('container py-4 d-flex flex-column', {
          'anim-slide-up': isVisible,
        })}
      >
        <Heading
          as="h2"
          className="mb-4"
          size="xl"
          weight="bold"
          color="secondary"
          style={{ textAlign: 'center' }}
        >
          Nossas soluções
        </Heading>

        {/* Buttons */}
        <div className={styles.tabWrapper}>
          {solutions.map((s) => (
            <GradientButton
              key={s.id}
              active={s.id === selected}
              onClick={() => setSelected(s.id)}
            >
              <GradientIcon icon={s.tabIcon} />
              {s.tabName}
            </GradientButton>
          ))}
        </div>
      </div>

      <div className={styles.tabPaneWrapper}>
        <div className={classNames(styles.bgEffect, 'bg-gradient-45')} />

        {/* Tab panes */}
        {solutions.map((s) => (
          <div
            key={s.id}
            className={classNames(styles.tabPane, 'anim anim-fade-in visible', {
              'd-none': s.id !== selected,
            })}
          >
            <div key={s.id} className="row g-3 flex-items-center">
              <div className="col-12 col-lg-7">
                <Image className={styles.image} src={s.image} alt={s.tabName} />
              </div>
              <div className={classNames(styles.tabContent, 'col-12 col-lg-5')}>
                <GradientIcon icon={s.tabIcon} className="mb-3" />
                <Heading className="mb-2" as="h3" size="lg2" weight="bold">
                  {s.tabName}
                </Heading>
                <Text className={classNames(styles.content, 'mb-3')} size="lg1">
                  {s.content}
                </Text>
                <Link to={s.link}>
                  <Button variant="outline" size="lg" appendIcon={faRight}>
                    Quero conhecer
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        ))}

        <BarsGraphic className={classNames(styles.graphic, styles.bars)} />
      </div>
    </div>
  );
};

export default HomePageSolutionSection;
