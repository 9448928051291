import React from 'react';
import Slider, { CarouselProps } from 'react-multi-carousel';
import { theme } from '@quality24/design-system';

import 'react-multi-carousel/lib/styles.css';

const intBreakpoints: Record<keyof typeof theme.breakpoints, number> =
  Object.entries(theme.breakpoints).reduce((acc, [key, value]) => {
    acc[key as keyof typeof theme.breakpoints] = parseInt(
      value.replace('px', ''),
      10,
    );
    return acc;
  }, {} as Record<keyof typeof theme.breakpoints, number>);

const defaultSettings: Omit<CarouselProps, 'children'> = {
  responsive: {
    desktop: {
      breakpoint: { max: 4000, min: intBreakpoints.sm },
      items: 4,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: intBreakpoints.sm, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  },
  infinite: false,
  autoPlay: false,
  autoPlaySpeed: 2000,
  slidesToSlide: 1,
  partialVisible: true,
  centerMode: false,
  ssr: true,
};

export interface Props extends Omit<CarouselProps, 'responsive'> {
  responsive?: CarouselProps['responsive'];
  children: React.ReactNode;
}

const Carousel: React.FunctionComponent<Props> = ({
  children,
  ...sliderProps
}) => {
  return (
    <Slider {...defaultSettings} {...sliderProps}>
      {children}
    </Slider>
  );
};

export default Carousel;
