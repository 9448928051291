import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { navigate } from 'gatsby';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  faAt,
  faBuilding,
  faPhone,
  faSend,
  faUserDoctor,
} from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import {
  Alert,
  Button,
  Icon,
  InputWithIcon,
  PhoneInput,
  Spinner,
  TextArea,
} from '@quality24/design-system';

import { sendContactEmail } from '../../../services/email';
import { formatContactEmailParams } from '../../../utils/email';

import * as styles from './ContactForm.module.scss';

export interface FormValues extends Record<string, string> {
  name: string;
  phone: string;
  company: string;
  email: string;
  message: string;
}

/**
 * Form initial values
 */
const initialValues: FormValues = {
  name: '',
  phone: '',
  company: '',
  email: '',
  message: '',
};

/**
 * Form validation schema
 */
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Campo Obrigatório'),
  phone: Yup.string(),
  company: Yup.string(),
  email: Yup.string().email('Email inválido').required('Campo Obrigatório'),
  message: Yup.string(),
});

const ContactForm: React.FunctionComponent<{ className?: string }> = ({
  className,
}) => {
  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const [emailError, setEmailError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  /**
   * Handles the form submission
   */
  const handleFormSubmit = React.useCallback(
    async (values: FormValues): Promise<void> => {
      if (!recaptchaRef || !recaptchaRef.current) return;

      try {
        // validate reCAPTCHA
        await recaptchaRef.current.execute();
        setLoading(true);

        // send email
        await sendContactEmail(formatContactEmailParams(values));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        setEmailError(true);
      } finally {
        setLoading(false);
        navigate('/company/thank-you');
      }
    },
    [recaptchaRef],
  );

  // Create the formik state
  const { handleSubmit, handleChange, touched, values, errors } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleFormSubmit,
  });

  return (
    <form
      className={classNames(styles.form, className)}
      onSubmit={handleSubmit}
    >
      {emailError && (
        <Alert
          title="Ops, algo aconteceu"
          variant="danger"
          onDismiss={() => setEmailError(false)}
        >
          Não conseguimos enviar seu contato, por favor tente novamente.
        </Alert>
      )}

      <InputWithIcon
        className={styles.input}
        helperText="Campo Obrigatório"
        name="name"
        label="Nome"
        prependIcon={faUserDoctor}
        placeholder="Seu nome para identificação"
        value={values.name}
        errorText={(touched.name && errors.name) || ''}
        disabled={loading}
        onChange={handleChange}
      />

      <div className="row g-4">
        <div className="col-12 col-md-6">
          <PhoneInput
            className={styles.input}
            name="phone"
            label="Telefone"
            prependIcon={faPhone}
            placeholder="Telefone ou whatsapp"
            value={values.phone}
            errorText={(touched.phone && errors.phone) || ''}
            disabled={loading}
            onChange={handleChange}
          />
        </div>

        <div className="col-12 col-md-6">
          <InputWithIcon
            className={styles.input}
            name="company"
            label="Empresa"
            prependIcon={faBuilding}
            placeholder="Nome da empresa"
            value={values.company}
            errorText={(touched.company && errors.company) || ''}
            disabled={loading}
            onChange={handleChange}
          />
        </div>
      </div>

      <InputWithIcon
        className={styles.input}
        name="email"
        helperText="Campo Obrigatório"
        type="email"
        label="E-mail"
        prependIcon={faAt}
        placeholder="E-mail para contato"
        value={values.email}
        errorText={(touched.email && errors.email) || ''}
        disabled={loading}
        onChange={handleChange}
      />

      <TextArea
        className={styles.input}
        name="message"
        label="Mensagem"
        placeholder="Sua mensagem"
        value={values.message}
        errorText={(touched.message && errors.message) || ''}
        disabled={loading}
        onChange={handleChange}
      />

      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.GATSBY_RECAPTCHA_CLIENT_KEY as string}
      />

      <Button
        className={styles.button}
        type="submit"
        variant="outline"
        size="lg"
        disabled={!values.name || !values.email || loading}
      >
        {loading && <Spinner size="xs" />}
        {loading ? 'Enviando...' : 'Enviar mensagem'}
        <Icon icon={faSend} />
      </Button>
    </form>
  );
};

export default ContactForm;
