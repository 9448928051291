import React from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { Heading, Icon, Text } from '@quality24/design-system';

import useOnScreen from '../../../utils/useOnScreen';

import * as styles from './HighlightCircle.module.scss';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  icon?: FontAwesomeIconProps['icon'];
  prependSign?: React.ReactNode;
  appendSign?: React.ReactNode;
  number: number;
  onLightBackground?: boolean;
  children?: React.ReactNode;
}

const HighlightCircle: React.FunctionComponent<Props> = ({
  className,
  icon,
  prependSign,
  appendSign,
  number,
  onLightBackground = false,
  children,
  ...props
}) => {
  const breakpoints = useBreakpoint();

  const [value, setValue] = React.useState(0);
  const intervalRef = React.useRef<null | NodeJS.Timer>(null);

  const elementRef = React.useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(elementRef);

  React.useEffect(() => {
    if (!isEmpty(breakpoints) && !breakpoints.md) {
      setValue(number);
    }
  }, [breakpoints, number]);

  // Set up the interval
  React.useEffect(() => {
    // Increments the number by 5% every 100ms
    intervalRef.current = setInterval(() => {
      if (!isVisible) return;

      setValue((prev) => {
        // Stop timer if value reached the expected number
        // @note: need to round the number due to it not being an integer
        if (Math.round(prev) === number) {
          clearInterval(intervalRef.current as NodeJS.Timer);
          return number;
        }

        const step = prev / number;
        return number * (step + 0.05);
      });
    }, 100);

    return () => clearInterval(intervalRef.current as NodeJS.Timer);
  }, [intervalRef, isVisible, number, setValue]);

  return (
    <div
      ref={elementRef}
      className={classNames(
        styles.wrapper,
        { 'on-light-bg': onLightBackground },
        className,
      )}
      {...props}
    >
      {icon && (
        <Icon
          className={classNames(styles.icon, 'mb-3')}
          icon={icon}
          size="lg"
        />
      )}

      <div>
        <Heading className={styles.title} as="h3" size="xl" weight="bold">
          {prependSign}
          {value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
          {appendSign}
        </Heading>
        <Text className={styles.subtitle}>{children}</Text>
      </div>
    </div>
  );
};

export default HighlightCircle;
