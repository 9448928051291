import {
  faBedPulse,
  faHandHoldingMedical,
  faMessageSmile,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

import inpatientImg from '../../../assets/solutions/inpatient1.webp';
import feedbackImg from '../../../assets/solutions/feedback0.webp';
import ambulatoryImg from '../../../assets/solutions/outpatient0.webp';

export interface SolutionOverviewData {
  id: string;
  image: string;
  tabName: string;
  tabIcon: FontAwesomeIconProps['icon'];
  content: string;
  link: string;
}

// eslint-disable-next-line import/prefer-default-export
export const solutions: Array<SolutionOverviewData> = [
  {
    id: 'inpatient',
    image: inpatientImg,
    tabIcon: faBedPulse,
    tabName: 'Internação',
    content:
      'Otimize a operação, reduza custos e ofereça um atendimento mais exclusivo aos seus pacientes e acompanhantes',
    link: 'solutions/internacao',
  },
  {
    id: 'ambulatory',
    image: ambulatoryImg,
    tabIcon: faHandHoldingMedical,
    tabName: 'Ambulatorial',
    content:
      'Facilite o processo de recepção, elimine filas e identifique os principais gargalos dentro do seu hospital',
    link: 'solutions/ambulatorial',
  },
  {
    id: 'feedback',
    image: feedbackImg,
    tabIcon: faMessageSmile,
    tabName: 'Voz do Paciente',
    content:
      'Elimine a burocracia, dê mais agilidade para o SAC e Ouvidoria, e aumente a conexão/interação com o seu paciente',
    link: 'solutions/vozdopaciente',
  },
];
