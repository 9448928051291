// extracted by mini-css-extract-plugin
export var bars = "HomePageSolutionSection-module--bars--e6d2b";
export var bgEffect = "HomePageSolutionSection-module--bg-effect--7e354";
export var content = "HomePageSolutionSection-module--content--7da96";
export var graphic = "HomePageSolutionSection-module--graphic--4adbd";
export var image = "HomePageSolutionSection-module--image--44fc7";
export var tabContent = "HomePageSolutionSection-module--tab-content--8f9e2";
export var tabPane = "HomePageSolutionSection-module--tab-pane--68de2";
export var tabPaneWrapper = "HomePageSolutionSection-module--tab-pane-wrapper--ebcf0";
export var tabWrapper = "HomePageSolutionSection-module--tab-wrapper--da337";
export var wrapper = "HomePageSolutionSection-module--wrapper--e571d";